import 'vuetify/styles'

import {
  mdiAccount,
  mdiAccountCircle,
  mdiAccountLock,
  mdiAccountMultiple,
  mdiAlert,
  mdiAlertCircle,
  mdiApi,
  mdiArrowCollapseLeft,
  mdiArrowCollapseRight,
  mdiArrowCollapseVertical,
  mdiArrowDecision,
  mdiArrowDown,
  mdiArrowExpandVertical,
  mdiArrowLeft,
  mdiArrowRight,
  mdiArrowUp,
  mdiAttachment,
  mdiBell,
  mdiBullhorn,
  mdiBullhornOutline,
  mdiCallSplit,
  mdiChartBox,
  mdiCheck,
  mdiCheckCircle,
  mdiChevronDown,
  mdiChevronRight,
  mdiChevronUp,
  mdiClose,
  mdiCloud,
  mdiCog,
  mdiContentCopy,
  mdiCurrencyJpy,
  mdiDatabase,
  mdiDelete,
  mdiDomain,
  mdiDotsVertical,
  mdiDownload,
  mdiDrag,
  mdiEarth,
  mdiEmail,
  mdiEye,
  mdiEyeOff,
  mdiFaceAgent,
  mdiFamilyTree,
  mdiFile,
  mdiFileCheck,
  mdiFilePdfBox,
  mdiFilePlus,
  mdiFileUpload,
  mdiFilterVariant,
  mdiFlag,
  mdiFolder,
  mdiFolderOpen,
  mdiFormatColorText,
  mdiHelpCircle,
  mdiHistory,
  mdiHome,
  mdiInformation,
  mdiLabel,
  mdiLightbulb,
  mdiLightningBolt,
  mdiLink,
  mdiLinkPlus,
  mdiLock,
  mdiLockOpen,
  mdiMagnify,
  mdiMenuDown,
  mdiMenuRight,
  mdiMenuUp,
  mdiMicrosoftExcel,
  mdiOpenInNew,
  mdiPause,
  mdiPencil,
  mdiPlay,
  mdiPlus,
  mdiPlusCircle,
  mdiPrinter,
  mdiRayStartVertexEnd,
  mdiRefresh,
  mdiRoutes,
  mdiSecurity,
  mdiSend,
  mdiShare,
  mdiShieldCheck,
  mdiSkipNext,
  mdiStar,
  mdiStarCircle,
  mdiSwapVertical,
  mdiTable,
  mdiTableCog,
  mdiTag,
  mdiTransitConnectionVariant,
  mdiUndo,
  mdiViewList,
  mdiWrench,
} from '@mdi/js'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { en, ja } from 'vuetify/locale'

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    // すべてのコンポーネントを使用
    components: {
      ...components,
    },
    // すべてのディレクティブを使用
    directives,
    // ロケール
    locale: {
      locale: 'ja',
      fallback: 'en',
      messages: { ja, en },
    },
    // ブレークポイントの設定
    // md（960px）以上の場合はデスクトップ、md未満の場合はモバイルと判定する
    display: {
      mobileBreakpoint: 'md',
    },
    // アイコンフォントの設定
    icons: {
      defaultSet: 'mdi',
      aliases: {
        ...aliases,
        mdiAccount,
        mdiAccountCircle,
        mdiAccountLock,
        mdiAccountMultiple,
        mdiAlert,
        mdiAlertCircle,
        mdiApi,
        mdiArrowCollapseLeft,
        mdiArrowCollapseRight,
        mdiArrowCollapseVertical,
        mdiArrowDecision,
        mdiArrowDown,
        mdiArrowExpandVertical,
        mdiArrowLeft,
        mdiArrowRight,
        mdiArrowUp,
        mdiAttachment,
        mdiBell,
        mdiBullhorn,
        mdiBullhornOutline,
        mdiCallSplit,
        mdiChartBox,
        mdiCheck,
        mdiCheckCircle,
        mdiChevronDown,
        mdiChevronUp,
        mdiChevronRight,
        mdiClose,
        mdiCloud,
        mdiCog,
        mdiContentCopy,
        mdiCurrencyJpy,
        mdiDatabase,
        mdiDelete,
        mdiDomain,
        mdiDotsVertical,
        mdiDownload,
        mdiDrag,
        mdiEarth,
        mdiEmail,
        mdiEye,
        mdiEyeOff,
        mdiFaceAgent,
        mdiFamilyTree,
        mdiFile,
        mdiFileCheck,
        mdiFilePdfBox,
        mdiFilePlus,
        mdiFileUpload,
        mdiFilterVariant,
        mdiFlag,
        mdiFolder,
        mdiTransitConnectionVariant,
        mdiFolderOpen,
        mdiFormatColorText,
        mdiHelpCircle,
        mdiHistory,
        mdiHome,
        mdiInformation,
        mdiLabel,
        mdiLightbulb,
        mdiLightningBolt,
        mdiLink,
        mdiLinkPlus,
        mdiLock,
        mdiLockOpen,
        mdiMagnify,
        mdiMenuDown,
        mdiMenuRight,
        mdiMenuUp,
        mdiMicrosoftExcel,
        mdiOpenInNew,
        mdiPause,
        mdiPencil,
        mdiPlay,
        mdiPlus,
        mdiPlusCircle,
        mdiPrinter,
        mdiRayStartVertexEnd,
        mdiRefresh,
        mdiRoutes,
        mdiSecurity,
        mdiSend,
        mdiShare,
        mdiShieldCheck,
        mdiSkipNext,
        mdiStar,
        mdiStarCircle,
        mdiSwapVertical,
        mdiTable,
        mdiTableCog,
        mdiTag,
        mdiUndo,
        mdiViewList,
        mdiWrench,
      },
      sets: {
        mdi,
      },
    },
    // テーマの設定
    theme: {
      defaultTheme: 'defaultTheme',
      variations: {
        colors: ['primary', 'secondary'],
        lighten: 5,
        darken: 3,
      },
      themes: {
        defaultTheme: {
          dark: false,
          colors: {
            // デフォルトで定義されている色
            background: '#F5F6FA',
            surface: '#FFFFFF',
            primary: '#2850DC',
            secondary: '#FF648C',
            success: '#4CAF50',
            info: '#2196F3',
            warning: '#FF8800',
            error: '#F44336',

            // 独自の色
            accent: '#FF648C', // TODO: secondaryに統一
            anchor: '#3C64C8',
            anchorIcon: '#3C64C8', // TODO: anchorに統一
            grey: '#9E9E9E', // vuetify grey
            border: '#D6D6D6',
            column: '#F9F9F9',
            drawer: '#00143C',
            drawerAdmin: '#333333',
          },
        },
      },
    },
    // Date pickerの日付フォーマットを指定（「31日」ではなく「31」と表示する）
    date: {
      formats: {
        // @ts-expect-error Vuetify の設定であり、型を指定できないため @ts-expect-error を付与
        dayOfMonth: (date, _formatString, _locale) => date.getDate(),
      },
    },
  })

  // Vue.js で Vuetify を使用する
  nuxtApp.vueApp.use(vuetify)
})
