import { default as index3jigXpAgseMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/audit/index.vue?macro=true";
import { default as indexrJYkRbncZFMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/authentication/index.vue?macro=true";
import { default as editnXcFd9jFCVMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/automations/[id]/edit.vue?macro=true";
import { default as indexiVUOLYVNQvMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/automations/index.vue?macro=true";
import { default as newtSAlptvFFpMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/automations/new.vue?macro=true";
import { default as indexl8COoRjLq6Meta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/billing/index.vue?macro=true";
import { default as indexkxDgxJ3kdfMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/calculationVariables/index.vue?macro=true";
import { default as indexv8Gvtn9aduMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/categories/index.vue?macro=true";
import { default as callback6bp5CbaB6ZMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/box/callback.vue?macro=true";
import { default as indexVLSTAJE2XxMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/box/index.vue?macro=true";
import { default as redirectmitYH75xXxMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/box/redirect.vue?macro=true";
import { default as indexIea7Lpu89RMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/chatwork/index.vue?macro=true";
import { default as indexWUF2Ytk40MMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/climberCloud/index.vue?macro=true";
import { default as indexgMVoWQtsyAMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/googleDrive/index.vue?macro=true";
import { default as indexuzYrpb1vm8Meta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/gwsDirectory/index.vue?macro=true";
import { default as resultsFc7dxU3YqeMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/gwsDirectory/results.vue?macro=true";
import { default as indexdHO5TaqykLMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/lineWorks/index.vue?macro=true";
import { default as callbackftNoeBo3cJMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/microsoftTeams/callback.vue?macro=true";
import { default as indexmUYdccd3qOMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/microsoftTeams/index.vue?macro=true";
import { default as indexxgMieOL6H6Meta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/serviceAccounts/index.vue?macro=true";
import { default as callbackA76YAVb5ayMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/slack/callback.vue?macro=true";
import { default as indexy3FcctfJb0Meta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/slack/index.vue?macro=true";
import { default as redirectp6ljMuy10NMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/slack/redirect.vue?macro=true";
import { default as _91id_93cwAzSDy1DPMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/webhook/[id].vue?macro=true";
import { default as indexk0KbC8AqAqMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/webhook/index.vue?macro=true";
import { default as _91id_93HpeSqeFTXbMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/folders/[id].vue?macro=true";
import { default as indexxWISO1CwwnMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/folders/index.vue?macro=true";
import { default as indexNYvKUglUhuMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/grades/index.vue?macro=true";
import { default as indexiRecd84KGRMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/index.vue?macro=true";
import { default as indexvuXdWrmqG9Meta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/invitationDomains/index.vue?macro=true";
import { default as indexuyjwTf5RlEMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/invitationSetting/index.vue?macro=true";
import { default as indexEH2FbzE1i5Meta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/ip/index.vue?macro=true";
import { default as indexH4d38RyiARMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/labels/index.vue?macro=true";
import { default as index6usX7M0aGXMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/masters/[id]/index.vue?macro=true";
import { default as workflowspwWo6gIrb0Meta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/masters/[id]/workflows.vue?macro=true";
import { default as indexBPgQPJf5QWMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/masters/index.vue?macro=true";
import { default as indexEfTQKCT5NYMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/organizations/[org]/compare/index.vue?macro=true";
import { default as csv99d89zwu7RMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/organizations/[org]/csv.vue?macro=true";
import { default as index8pZyCFl1jjMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/organizations/[org]/index.vue?macro=true";
import { default as _91id_93xDZDVZdxhCMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/organizations/[org]/teams/[id].vue?macro=true";
import { default as historyVWFtjnb0tyMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/organizations/history.vue?macro=true";
import { default as indexgvfMUXlTpzMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/organizations/index.vue?macro=true";
import { default as _91id_935q4jltiU3mMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/pipelines/[id].vue?macro=true";
import { default as indexHEtVUKi8LUMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/pipelines/index.vue?macro=true";
import { default as newjUx0NGAIdsMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/pipelines/new.vue?macro=true";
import { default as indexb6GQEaMGaBMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/proxyApplicants/index.vue?macro=true";
import { default as indexCxLN3eGXPzMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/proxyApprovers/index.vue?macro=true";
import { default as _91id_93kWzFJHBvVWMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/roles/[id].vue?macro=true";
import { default as indexqqjDsVDIwFMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/roles/index.vue?macro=true";
import { default as editMPVt0pxrAFMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/routes/[id]/edit.vue?macro=true";
import { default as indexnfApTOrctuMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/routes/[id]/index.vue?macro=true";
import { default as indexCtXSCP46OVMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/routes/[id]/simulator/index.vue?macro=true";
import { default as _91versionNumber_93DBuNfrHx8FMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/routes/[id]/versions/[versionNumber].vue?macro=true";
import { default as indexRJTvO51e0mMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/routes/[id]/versions/index.vue?macro=true";
import { default as indexYR7kY3XDBeMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/routes/index.vue?macro=true";
import { default as newHwwxvhmiNgMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/routes/new.vue?macro=true";
import { default as indexINbUjom2ZvMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/stats/index.vue?macro=true";
import { default as indexGPX1oE64glMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/subStatuses/index.vue?macro=true";
import { default as assistantsv9m6vPpvHyMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/assistants.vue?macro=true";
import { default as backups98gcqaB35CMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/backups.vue?macro=true";
import { default as index8FiRIOx01vMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/index.vue?macro=true";
import { default as newsT2ylJ1IJN2Meta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/news.vue?macro=true";
import { default as notificationsXPcsF2vB2fMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/notifications.vue?macro=true";
import { default as remind26ftoXEobNMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/remind.vue?macro=true";
import { default as settingsZkMQ5fZSMcMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/settings.vue?macro=true";
import { default as subdomainIqbH5F3A8AMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/subdomain.vue?macro=true";
import { default as workingZtplU80PGDMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/working.vue?macro=true";
import { default as workflowsjtCvPDXuoMMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/ticketNumberKeys/[id]/workflows.vue?macro=true";
import { default as indexrhI7IH9Sg7Meta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/ticketNumberKeys/index.vue?macro=true";
import { default as cleanhP4adauB7fMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tickets/clean.vue?macro=true";
import { default as myticketwV616Cs8NMMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tickets/transfer/myticket.vue?macro=true";
import { default as taskTDtQnpLgouMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tickets/transfer/task.vue?macro=true";
import { default as viewerFqwICtXmj3Meta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tickets/viewer.vue?macro=true";
import { default as _91id_93YbD8u6w1JiMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/users/[id].vue?macro=true";
import { default as csv6w9fEyMqTmMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/users/csv.vue?macro=true";
import { default as indexHMUlU4syyzMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/users/index.vue?macro=true";
import { default as inviteddHjjJe67ZMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/users/invite.vue?macro=true";
import { default as unaffiliatedrdQoSyFCCUMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/users/unaffiliated.vue?macro=true";
import { default as editSOCoNzGUiAMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/workflows/[id]/edit.vue?macro=true";
import { default as indexh5ZGaEyGAfMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/workflows/[id]/index.vue?macro=true";
import { default as _91versionNumber_93vY2OwP4sEXMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/workflows/[id]/versions/[versionNumber].vue?macro=true";
import { default as index8Ta4tSjwWtMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/workflows/[id]/versions/index.vue?macro=true";
import { default as indexnzYRUUftblMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/workflows/index.vue?macro=true";
import { default as newnb0HmYBVuGMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/workflows/new.vue?macro=true";
import { default as admin8SbE7QwdzaMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/admin.vue?macro=true";
import { default as signin0qBOgHLFJtMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/assistants/[id]/signin.vue?macro=true";
import { default as callbackESOz4FBvFZMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/auth/callback.vue?macro=true";
import { default as callback22koegqrpIbMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/auth/callback2.vue?macro=true";
import { default as googleCallback27x6yZi5iXLMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/auth/googleCallback2.vue?macro=true";
import { default as verify_emailJylZemT3iMMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/auth/verify_email.vue?macro=true";
import { default as index6QAsXfMQzrMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/index.vue?macro=true";
import { default as index9nYeGPSohWMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/mytickets/index.vue?macro=true";
import { default as newDZmt8c5GdQMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/mytickets/new.vue?macro=true";
import { default as indexcTmtn76czvMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/notifications/index.vue?macro=true";
import { default as indexMmaV7NB0zXMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/profile/index.vue?macro=true";
import { default as indexPWi64yLcCWMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/security/index.vue?macro=true";
import { default as cloudsignhydR3jv7YmMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/settings/cloudsign.vue?macro=true";
import { default as googleChatBP5O4DA3LoMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/settings/googleChat.vue?macro=true";
import { default as notificationslPS2aiy0aMMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/settings/notifications.vue?macro=true";
import { default as tokensjBxuxlgk4kMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/settings/tokens.vue?macro=true";
import { default as indexRlpBM5oWVNMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/tasks/index.vue?macro=true";
import { default as editIWzQ20BBHRMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/tickets/[id]/edit.vue?macro=true";
import { default as editByApprovercaHoqaINuVMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/tickets/[id]/editByApprover.vue?macro=true";
import { default as indexF14yBGGtj4Meta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/tickets/[id]/index.vue?macro=true";
import { default as printBUE8G6pdTGMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/tickets/[id]/print.vue?macro=true";
import { default as indexlTQypAy9dQMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/tickets/index.vue?macro=true";
import { default as indexd8fmphOnXNMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/view/index.vue?macro=true";
import { default as editFFghNnJPFHMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/view/settings/[id]/edit.vue?macro=true";
import { default as indexkhkbX28QMlMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/view/settings/index.vue?macro=true";
import { default as newSHe5qWGRJOMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/view/settings/new.vue?macro=true";
import { default as searchGn3Iu6JsjKMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/workflows/search.vue?macro=true";
import { default as dashboards3oIHECcznMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard.vue?macro=true";
import { default as expiredPMBS3cvtvyMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/expired.vue?macro=true";
import { default as indexJwMW7YrwrYMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/files/[id]/index.vue?macro=true";
import { default as indexOSmglaBbVNMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/index.vue?macro=true";
import { default as _91id_938bMCeas1YeMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/invitations/[id].vue?macro=true";
import { default as misoca0JBZbVhZDWMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/misoca.vue?macro=true";
import { default as redirectgVKnqaIg6DMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/redirect.vue?macro=true";
import { default as selectWMYo1Zfc5kMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/select.vue?macro=true";
import { default as signinQbBQywGHCmMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/signin.vue?macro=true";
import { default as startSsoMx3lqgaJJKMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/startSso.vue?macro=true";
import { default as welcomeAYOeiRsPBlMeta } from "/tmp/build_6214dcf5/frontend_nuxt3/pages/welcome.vue?macro=true";
export default [
  {
    name: admin8SbE7QwdzaMeta?.name,
    path: "/en/admin",
    meta: admin8SbE7QwdzaMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin.vue"),
    children: [
  {
    name: "admin-audit___en",
    path: "audit",
    meta: index3jigXpAgseMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/audit/index.vue")
  },
  {
    name: "admin-authentication___en",
    path: "authentication",
    meta: indexrJYkRbncZFMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/authentication/index.vue")
  },
  {
    name: "admin-automations-id-edit___en",
    path: "automations/:id()/edit",
    meta: editnXcFd9jFCVMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/automations/[id]/edit.vue")
  },
  {
    name: "admin-automations___en",
    path: "automations",
    meta: indexiVUOLYVNQvMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/automations/index.vue")
  },
  {
    name: "admin-automations-new___en",
    path: "automations/new",
    meta: newtSAlptvFFpMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/automations/new.vue")
  },
  {
    name: "admin-billing___en",
    path: "billing",
    meta: indexl8COoRjLq6Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/billing/index.vue")
  },
  {
    name: "admin-calculationVariables___en",
    path: "calculationVariables",
    meta: indexkxDgxJ3kdfMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/calculationVariables/index.vue")
  },
  {
    name: "admin-categories___en",
    path: "categories",
    meta: indexv8Gvtn9aduMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/categories/index.vue")
  },
  {
    name: "admin-connections-box-callback___en",
    path: "connections/box/callback",
    meta: callback6bp5CbaB6ZMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/box/callback.vue")
  },
  {
    name: "admin-connections-box___en",
    path: "connections/box",
    meta: indexVLSTAJE2XxMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/box/index.vue")
  },
  {
    name: "admin-connections-box-redirect___en",
    path: "connections/box/redirect",
    meta: redirectmitYH75xXxMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/box/redirect.vue")
  },
  {
    name: "admin-connections-chatwork___en",
    path: "connections/chatwork",
    meta: indexIea7Lpu89RMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/chatwork/index.vue")
  },
  {
    name: "admin-connections-climberCloud___en",
    path: "connections/climberCloud",
    meta: indexWUF2Ytk40MMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/climberCloud/index.vue")
  },
  {
    name: "admin-connections-googleDrive___en",
    path: "connections/googleDrive",
    meta: indexgMVoWQtsyAMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/googleDrive/index.vue")
  },
  {
    name: "admin-connections-gwsDirectory___en",
    path: "connections/gwsDirectory",
    meta: indexuzYrpb1vm8Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/gwsDirectory/index.vue")
  },
  {
    name: "admin-connections-gwsDirectory-results___en",
    path: "connections/gwsDirectory/results",
    meta: resultsFc7dxU3YqeMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/gwsDirectory/results.vue")
  },
  {
    name: "admin-connections-lineWorks___en",
    path: "connections/lineWorks",
    meta: indexdHO5TaqykLMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/lineWorks/index.vue")
  },
  {
    name: "admin-connections-microsoftTeams-callback___en",
    path: "connections/microsoftTeams/callback",
    meta: callbackftNoeBo3cJMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/microsoftTeams/callback.vue")
  },
  {
    name: "admin-connections-microsoftTeams___en",
    path: "connections/microsoftTeams",
    meta: indexmUYdccd3qOMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/microsoftTeams/index.vue")
  },
  {
    name: "admin-connections-serviceAccounts___en",
    path: "connections/serviceAccounts",
    meta: indexxgMieOL6H6Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/serviceAccounts/index.vue")
  },
  {
    name: "admin-connections-slack-callback___en",
    path: "connections/slack/callback",
    meta: callbackA76YAVb5ayMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/slack/callback.vue")
  },
  {
    name: "admin-connections-slack___en",
    path: "connections/slack",
    meta: indexy3FcctfJb0Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/slack/index.vue")
  },
  {
    name: "admin-connections-slack-redirect___en",
    path: "connections/slack/redirect",
    meta: redirectp6ljMuy10NMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/slack/redirect.vue")
  },
  {
    name: "admin-connections-webhook-id___en",
    path: "connections/webhook/:id()",
    meta: _91id_93cwAzSDy1DPMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/webhook/[id].vue")
  },
  {
    name: "admin-connections-webhook___en",
    path: "connections/webhook",
    meta: indexk0KbC8AqAqMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/webhook/index.vue")
  },
  {
    name: "admin-folders-id___en",
    path: "folders/:id()",
    meta: _91id_93HpeSqeFTXbMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/folders/[id].vue")
  },
  {
    name: "admin-folders___en",
    path: "folders",
    meta: indexxWISO1CwwnMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/folders/index.vue")
  },
  {
    name: "admin-grades___en",
    path: "grades",
    meta: indexNYvKUglUhuMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/grades/index.vue")
  },
  {
    name: "admin___en",
    path: "",
    meta: indexiRecd84KGRMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/index.vue")
  },
  {
    name: "admin-invitationDomains___en",
    path: "invitationDomains",
    meta: indexvuXdWrmqG9Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/invitationDomains/index.vue")
  },
  {
    name: "admin-invitationSetting___en",
    path: "invitationSetting",
    meta: indexuyjwTf5RlEMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/invitationSetting/index.vue")
  },
  {
    name: "admin-ip___en",
    path: "ip",
    meta: indexEH2FbzE1i5Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/ip/index.vue")
  },
  {
    name: "admin-labels___en",
    path: "labels",
    meta: indexH4d38RyiARMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/labels/index.vue")
  },
  {
    name: "admin-masters-id___en",
    path: "masters/:id()",
    meta: index6usX7M0aGXMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/masters/[id]/index.vue")
  },
  {
    name: "admin-masters-id-workflows___en",
    path: "masters/:id()/workflows",
    meta: workflowspwWo6gIrb0Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/masters/[id]/workflows.vue")
  },
  {
    name: "admin-masters___en",
    path: "masters",
    meta: indexBPgQPJf5QWMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/masters/index.vue")
  },
  {
    name: "admin-organizations-org-compare___en",
    path: "organizations/:org()/compare",
    meta: indexEfTQKCT5NYMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/organizations/[org]/compare/index.vue")
  },
  {
    name: "admin-organizations-org-csv___en",
    path: "organizations/:org()/csv",
    meta: csv99d89zwu7RMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/organizations/[org]/csv.vue")
  },
  {
    name: "admin-organizations-org___en",
    path: "organizations/:org()",
    meta: index8pZyCFl1jjMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/organizations/[org]/index.vue")
  },
  {
    name: "admin-organizations-org-teams-id___en",
    path: "organizations/:org()/teams/:id()",
    meta: _91id_93xDZDVZdxhCMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/organizations/[org]/teams/[id].vue")
  },
  {
    name: "admin-organizations-history___en",
    path: "organizations/history",
    meta: historyVWFtjnb0tyMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/organizations/history.vue")
  },
  {
    name: "admin-organizations___en",
    path: "organizations",
    meta: indexgvfMUXlTpzMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/organizations/index.vue")
  },
  {
    name: "admin-pipelines-id___en",
    path: "pipelines/:id()",
    meta: _91id_935q4jltiU3mMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/pipelines/[id].vue")
  },
  {
    name: "admin-pipelines___en",
    path: "pipelines",
    meta: indexHEtVUKi8LUMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/pipelines/index.vue")
  },
  {
    name: "admin-pipelines-new___en",
    path: "pipelines/new",
    meta: newjUx0NGAIdsMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/pipelines/new.vue")
  },
  {
    name: "admin-proxyApplicants___en",
    path: "proxyApplicants",
    meta: indexb6GQEaMGaBMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/proxyApplicants/index.vue")
  },
  {
    name: "admin-proxyApprovers___en",
    path: "proxyApprovers",
    meta: indexCxLN3eGXPzMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/proxyApprovers/index.vue")
  },
  {
    name: "admin-roles-id___en",
    path: "roles/:id()",
    meta: _91id_93kWzFJHBvVWMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/roles/[id].vue")
  },
  {
    name: "admin-roles___en",
    path: "roles",
    meta: indexqqjDsVDIwFMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/roles/index.vue")
  },
  {
    name: "admin-routes-id-edit___en",
    path: "routes/:id()/edit",
    meta: editMPVt0pxrAFMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/routes/[id]/edit.vue")
  },
  {
    name: "admin-routes-id___en",
    path: "routes/:id()",
    meta: indexnfApTOrctuMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/routes/[id]/index.vue")
  },
  {
    name: "admin-routes-id-simulator___en",
    path: "routes/:id()/simulator",
    meta: indexCtXSCP46OVMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/routes/[id]/simulator/index.vue")
  },
  {
    name: "admin-routes-id-versions-versionNumber___en",
    path: "routes/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93DBuNfrHx8FMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/routes/[id]/versions/[versionNumber].vue")
  },
  {
    name: "admin-routes-id-versions___en",
    path: "routes/:id()/versions",
    meta: indexRJTvO51e0mMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/routes/[id]/versions/index.vue")
  },
  {
    name: "admin-routes___en",
    path: "routes",
    meta: indexYR7kY3XDBeMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/routes/index.vue")
  },
  {
    name: "admin-routes-new___en",
    path: "routes/new",
    meta: newHwwxvhmiNgMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/routes/new.vue")
  },
  {
    name: "admin-stats___en",
    path: "stats",
    meta: indexINbUjom2ZvMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/stats/index.vue")
  },
  {
    name: "admin-subStatuses___en",
    path: "subStatuses",
    meta: indexGPX1oE64glMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/subStatuses/index.vue")
  },
  {
    name: "admin-tenant-assistants___en",
    path: "tenant/assistants",
    meta: assistantsv9m6vPpvHyMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/assistants.vue")
  },
  {
    name: "admin-tenant-backups___en",
    path: "tenant/backups",
    meta: backups98gcqaB35CMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/backups.vue")
  },
  {
    name: "admin-tenant___en",
    path: "tenant",
    meta: index8FiRIOx01vMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/index.vue")
  },
  {
    name: "admin-tenant-news___en",
    path: "tenant/news",
    meta: newsT2ylJ1IJN2Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/news.vue")
  },
  {
    name: "admin-tenant-notifications___en",
    path: "tenant/notifications",
    meta: notificationsXPcsF2vB2fMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/notifications.vue")
  },
  {
    name: "admin-tenant-remind___en",
    path: "tenant/remind",
    meta: remind26ftoXEobNMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/remind.vue")
  },
  {
    name: "admin-tenant-settings___en",
    path: "tenant/settings",
    meta: settingsZkMQ5fZSMcMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/settings.vue")
  },
  {
    name: "admin-tenant-subdomain___en",
    path: "tenant/subdomain",
    meta: subdomainIqbH5F3A8AMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/subdomain.vue")
  },
  {
    name: "admin-tenant-working___en",
    path: "tenant/working",
    meta: workingZtplU80PGDMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/working.vue")
  },
  {
    name: "admin-ticketNumberKeys-id-workflows___en",
    path: "ticketNumberKeys/:id()/workflows",
    meta: workflowsjtCvPDXuoMMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/ticketNumberKeys/[id]/workflows.vue")
  },
  {
    name: "admin-ticketNumberKeys___en",
    path: "ticketNumberKeys",
    meta: indexrhI7IH9Sg7Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/ticketNumberKeys/index.vue")
  },
  {
    name: "admin-tickets-clean___en",
    path: "tickets/clean",
    meta: cleanhP4adauB7fMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tickets/clean.vue")
  },
  {
    name: "admin-tickets-transfer-myticket___en",
    path: "tickets/transfer/myticket",
    meta: myticketwV616Cs8NMMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tickets/transfer/myticket.vue")
  },
  {
    name: "admin-tickets-transfer-task___en",
    path: "tickets/transfer/task",
    meta: taskTDtQnpLgouMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tickets/transfer/task.vue")
  },
  {
    name: "admin-tickets-viewer___en",
    path: "tickets/viewer",
    meta: viewerFqwICtXmj3Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tickets/viewer.vue")
  },
  {
    name: "admin-users-id___en",
    path: "users/:id()",
    meta: _91id_93YbD8u6w1JiMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/users/[id].vue")
  },
  {
    name: "admin-users-csv___en",
    path: "users/csv",
    meta: csv6w9fEyMqTmMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/users/csv.vue")
  },
  {
    name: "admin-users___en",
    path: "users",
    meta: indexHMUlU4syyzMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/users/index.vue")
  },
  {
    name: "admin-users-invite___en",
    path: "users/invite",
    meta: inviteddHjjJe67ZMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/users/invite.vue")
  },
  {
    name: "admin-users-unaffiliated___en",
    path: "users/unaffiliated",
    meta: unaffiliatedrdQoSyFCCUMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/users/unaffiliated.vue")
  },
  {
    name: "admin-workflows-id-edit___en",
    path: "workflows/:id()/edit",
    meta: editSOCoNzGUiAMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/workflows/[id]/edit.vue")
  },
  {
    name: "admin-workflows-id___en",
    path: "workflows/:id()",
    meta: indexh5ZGaEyGAfMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/workflows/[id]/index.vue")
  },
  {
    name: "admin-workflows-id-versions-versionNumber___en",
    path: "workflows/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93vY2OwP4sEXMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/workflows/[id]/versions/[versionNumber].vue")
  },
  {
    name: "admin-workflows-id-versions___en",
    path: "workflows/:id()/versions",
    meta: index8Ta4tSjwWtMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/workflows/[id]/versions/index.vue")
  },
  {
    name: "admin-workflows___en",
    path: "workflows",
    meta: indexnzYRUUftblMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/workflows/index.vue")
  },
  {
    name: "admin-workflows-new___en",
    path: "workflows/new",
    meta: newnb0HmYBVuGMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/workflows/new.vue")
  }
]
  },
  {
    name: admin8SbE7QwdzaMeta?.name,
    path: "/admin",
    meta: admin8SbE7QwdzaMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin.vue"),
    children: [
  {
    name: "admin-audit___ja",
    path: "audit",
    meta: index3jigXpAgseMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/audit/index.vue")
  },
  {
    name: "admin-authentication___ja",
    path: "authentication",
    meta: indexrJYkRbncZFMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/authentication/index.vue")
  },
  {
    name: "admin-automations-id-edit___ja",
    path: "automations/:id()/edit",
    meta: editnXcFd9jFCVMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/automations/[id]/edit.vue")
  },
  {
    name: "admin-automations___ja",
    path: "automations",
    meta: indexiVUOLYVNQvMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/automations/index.vue")
  },
  {
    name: "admin-automations-new___ja",
    path: "automations/new",
    meta: newtSAlptvFFpMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/automations/new.vue")
  },
  {
    name: "admin-billing___ja",
    path: "billing",
    meta: indexl8COoRjLq6Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/billing/index.vue")
  },
  {
    name: "admin-calculationVariables___ja",
    path: "calculationVariables",
    meta: indexkxDgxJ3kdfMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/calculationVariables/index.vue")
  },
  {
    name: "admin-categories___ja",
    path: "categories",
    meta: indexv8Gvtn9aduMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/categories/index.vue")
  },
  {
    name: "admin-connections-box-callback___ja",
    path: "connections/box/callback",
    meta: callback6bp5CbaB6ZMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/box/callback.vue")
  },
  {
    name: "admin-connections-box___ja",
    path: "connections/box",
    meta: indexVLSTAJE2XxMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/box/index.vue")
  },
  {
    name: "admin-connections-box-redirect___ja",
    path: "connections/box/redirect",
    meta: redirectmitYH75xXxMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/box/redirect.vue")
  },
  {
    name: "admin-connections-chatwork___ja",
    path: "connections/chatwork",
    meta: indexIea7Lpu89RMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/chatwork/index.vue")
  },
  {
    name: "admin-connections-climberCloud___ja",
    path: "connections/climberCloud",
    meta: indexWUF2Ytk40MMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/climberCloud/index.vue")
  },
  {
    name: "admin-connections-googleDrive___ja",
    path: "connections/googleDrive",
    meta: indexgMVoWQtsyAMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/googleDrive/index.vue")
  },
  {
    name: "admin-connections-gwsDirectory___ja",
    path: "connections/gwsDirectory",
    meta: indexuzYrpb1vm8Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/gwsDirectory/index.vue")
  },
  {
    name: "admin-connections-gwsDirectory-results___ja",
    path: "connections/gwsDirectory/results",
    meta: resultsFc7dxU3YqeMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/gwsDirectory/results.vue")
  },
  {
    name: "admin-connections-lineWorks___ja",
    path: "connections/lineWorks",
    meta: indexdHO5TaqykLMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/lineWorks/index.vue")
  },
  {
    name: "admin-connections-microsoftTeams-callback___ja",
    path: "connections/microsoftTeams/callback",
    meta: callbackftNoeBo3cJMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/microsoftTeams/callback.vue")
  },
  {
    name: "admin-connections-microsoftTeams___ja",
    path: "connections/microsoftTeams",
    meta: indexmUYdccd3qOMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/microsoftTeams/index.vue")
  },
  {
    name: "admin-connections-serviceAccounts___ja",
    path: "connections/serviceAccounts",
    meta: indexxgMieOL6H6Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/serviceAccounts/index.vue")
  },
  {
    name: "admin-connections-slack-callback___ja",
    path: "connections/slack/callback",
    meta: callbackA76YAVb5ayMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/slack/callback.vue")
  },
  {
    name: "admin-connections-slack___ja",
    path: "connections/slack",
    meta: indexy3FcctfJb0Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/slack/index.vue")
  },
  {
    name: "admin-connections-slack-redirect___ja",
    path: "connections/slack/redirect",
    meta: redirectp6ljMuy10NMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/slack/redirect.vue")
  },
  {
    name: "admin-connections-webhook-id___ja",
    path: "connections/webhook/:id()",
    meta: _91id_93cwAzSDy1DPMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/webhook/[id].vue")
  },
  {
    name: "admin-connections-webhook___ja",
    path: "connections/webhook",
    meta: indexk0KbC8AqAqMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/connections/webhook/index.vue")
  },
  {
    name: "admin-folders-id___ja",
    path: "folders/:id()",
    meta: _91id_93HpeSqeFTXbMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/folders/[id].vue")
  },
  {
    name: "admin-folders___ja",
    path: "folders",
    meta: indexxWISO1CwwnMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/folders/index.vue")
  },
  {
    name: "admin-grades___ja",
    path: "grades",
    meta: indexNYvKUglUhuMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/grades/index.vue")
  },
  {
    name: "admin___ja",
    path: "",
    meta: indexiRecd84KGRMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/index.vue")
  },
  {
    name: "admin-invitationDomains___ja",
    path: "invitationDomains",
    meta: indexvuXdWrmqG9Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/invitationDomains/index.vue")
  },
  {
    name: "admin-invitationSetting___ja",
    path: "invitationSetting",
    meta: indexuyjwTf5RlEMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/invitationSetting/index.vue")
  },
  {
    name: "admin-ip___ja",
    path: "ip",
    meta: indexEH2FbzE1i5Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/ip/index.vue")
  },
  {
    name: "admin-labels___ja",
    path: "labels",
    meta: indexH4d38RyiARMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/labels/index.vue")
  },
  {
    name: "admin-masters-id___ja",
    path: "masters/:id()",
    meta: index6usX7M0aGXMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/masters/[id]/index.vue")
  },
  {
    name: "admin-masters-id-workflows___ja",
    path: "masters/:id()/workflows",
    meta: workflowspwWo6gIrb0Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/masters/[id]/workflows.vue")
  },
  {
    name: "admin-masters___ja",
    path: "masters",
    meta: indexBPgQPJf5QWMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/masters/index.vue")
  },
  {
    name: "admin-organizations-org-compare___ja",
    path: "organizations/:org()/compare",
    meta: indexEfTQKCT5NYMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/organizations/[org]/compare/index.vue")
  },
  {
    name: "admin-organizations-org-csv___ja",
    path: "organizations/:org()/csv",
    meta: csv99d89zwu7RMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/organizations/[org]/csv.vue")
  },
  {
    name: "admin-organizations-org___ja",
    path: "organizations/:org()",
    meta: index8pZyCFl1jjMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/organizations/[org]/index.vue")
  },
  {
    name: "admin-organizations-org-teams-id___ja",
    path: "organizations/:org()/teams/:id()",
    meta: _91id_93xDZDVZdxhCMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/organizations/[org]/teams/[id].vue")
  },
  {
    name: "admin-organizations-history___ja",
    path: "organizations/history",
    meta: historyVWFtjnb0tyMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/organizations/history.vue")
  },
  {
    name: "admin-organizations___ja",
    path: "organizations",
    meta: indexgvfMUXlTpzMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/organizations/index.vue")
  },
  {
    name: "admin-pipelines-id___ja",
    path: "pipelines/:id()",
    meta: _91id_935q4jltiU3mMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/pipelines/[id].vue")
  },
  {
    name: "admin-pipelines___ja",
    path: "pipelines",
    meta: indexHEtVUKi8LUMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/pipelines/index.vue")
  },
  {
    name: "admin-pipelines-new___ja",
    path: "pipelines/new",
    meta: newjUx0NGAIdsMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/pipelines/new.vue")
  },
  {
    name: "admin-proxyApplicants___ja",
    path: "proxyApplicants",
    meta: indexb6GQEaMGaBMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/proxyApplicants/index.vue")
  },
  {
    name: "admin-proxyApprovers___ja",
    path: "proxyApprovers",
    meta: indexCxLN3eGXPzMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/proxyApprovers/index.vue")
  },
  {
    name: "admin-roles-id___ja",
    path: "roles/:id()",
    meta: _91id_93kWzFJHBvVWMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/roles/[id].vue")
  },
  {
    name: "admin-roles___ja",
    path: "roles",
    meta: indexqqjDsVDIwFMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/roles/index.vue")
  },
  {
    name: "admin-routes-id-edit___ja",
    path: "routes/:id()/edit",
    meta: editMPVt0pxrAFMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/routes/[id]/edit.vue")
  },
  {
    name: "admin-routes-id___ja",
    path: "routes/:id()",
    meta: indexnfApTOrctuMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/routes/[id]/index.vue")
  },
  {
    name: "admin-routes-id-simulator___ja",
    path: "routes/:id()/simulator",
    meta: indexCtXSCP46OVMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/routes/[id]/simulator/index.vue")
  },
  {
    name: "admin-routes-id-versions-versionNumber___ja",
    path: "routes/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93DBuNfrHx8FMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/routes/[id]/versions/[versionNumber].vue")
  },
  {
    name: "admin-routes-id-versions___ja",
    path: "routes/:id()/versions",
    meta: indexRJTvO51e0mMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/routes/[id]/versions/index.vue")
  },
  {
    name: "admin-routes___ja",
    path: "routes",
    meta: indexYR7kY3XDBeMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/routes/index.vue")
  },
  {
    name: "admin-routes-new___ja",
    path: "routes/new",
    meta: newHwwxvhmiNgMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/routes/new.vue")
  },
  {
    name: "admin-stats___ja",
    path: "stats",
    meta: indexINbUjom2ZvMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/stats/index.vue")
  },
  {
    name: "admin-subStatuses___ja",
    path: "subStatuses",
    meta: indexGPX1oE64glMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/subStatuses/index.vue")
  },
  {
    name: "admin-tenant-assistants___ja",
    path: "tenant/assistants",
    meta: assistantsv9m6vPpvHyMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/assistants.vue")
  },
  {
    name: "admin-tenant-backups___ja",
    path: "tenant/backups",
    meta: backups98gcqaB35CMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/backups.vue")
  },
  {
    name: "admin-tenant___ja",
    path: "tenant",
    meta: index8FiRIOx01vMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/index.vue")
  },
  {
    name: "admin-tenant-news___ja",
    path: "tenant/news",
    meta: newsT2ylJ1IJN2Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/news.vue")
  },
  {
    name: "admin-tenant-notifications___ja",
    path: "tenant/notifications",
    meta: notificationsXPcsF2vB2fMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/notifications.vue")
  },
  {
    name: "admin-tenant-remind___ja",
    path: "tenant/remind",
    meta: remind26ftoXEobNMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/remind.vue")
  },
  {
    name: "admin-tenant-settings___ja",
    path: "tenant/settings",
    meta: settingsZkMQ5fZSMcMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/settings.vue")
  },
  {
    name: "admin-tenant-subdomain___ja",
    path: "tenant/subdomain",
    meta: subdomainIqbH5F3A8AMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/subdomain.vue")
  },
  {
    name: "admin-tenant-working___ja",
    path: "tenant/working",
    meta: workingZtplU80PGDMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tenant/working.vue")
  },
  {
    name: "admin-ticketNumberKeys-id-workflows___ja",
    path: "ticketNumberKeys/:id()/workflows",
    meta: workflowsjtCvPDXuoMMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/ticketNumberKeys/[id]/workflows.vue")
  },
  {
    name: "admin-ticketNumberKeys___ja",
    path: "ticketNumberKeys",
    meta: indexrhI7IH9Sg7Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/ticketNumberKeys/index.vue")
  },
  {
    name: "admin-tickets-clean___ja",
    path: "tickets/clean",
    meta: cleanhP4adauB7fMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tickets/clean.vue")
  },
  {
    name: "admin-tickets-transfer-myticket___ja",
    path: "tickets/transfer/myticket",
    meta: myticketwV616Cs8NMMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tickets/transfer/myticket.vue")
  },
  {
    name: "admin-tickets-transfer-task___ja",
    path: "tickets/transfer/task",
    meta: taskTDtQnpLgouMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tickets/transfer/task.vue")
  },
  {
    name: "admin-tickets-viewer___ja",
    path: "tickets/viewer",
    meta: viewerFqwICtXmj3Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/tickets/viewer.vue")
  },
  {
    name: "admin-users-id___ja",
    path: "users/:id()",
    meta: _91id_93YbD8u6w1JiMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/users/[id].vue")
  },
  {
    name: "admin-users-csv___ja",
    path: "users/csv",
    meta: csv6w9fEyMqTmMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/users/csv.vue")
  },
  {
    name: "admin-users___ja",
    path: "users",
    meta: indexHMUlU4syyzMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/users/index.vue")
  },
  {
    name: "admin-users-invite___ja",
    path: "users/invite",
    meta: inviteddHjjJe67ZMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/users/invite.vue")
  },
  {
    name: "admin-users-unaffiliated___ja",
    path: "users/unaffiliated",
    meta: unaffiliatedrdQoSyFCCUMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/users/unaffiliated.vue")
  },
  {
    name: "admin-workflows-id-edit___ja",
    path: "workflows/:id()/edit",
    meta: editSOCoNzGUiAMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/workflows/[id]/edit.vue")
  },
  {
    name: "admin-workflows-id___ja",
    path: "workflows/:id()",
    meta: indexh5ZGaEyGAfMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/workflows/[id]/index.vue")
  },
  {
    name: "admin-workflows-id-versions-versionNumber___ja",
    path: "workflows/:id()/versions/:versionNumber()",
    meta: _91versionNumber_93vY2OwP4sEXMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/workflows/[id]/versions/[versionNumber].vue")
  },
  {
    name: "admin-workflows-id-versions___ja",
    path: "workflows/:id()/versions",
    meta: index8Ta4tSjwWtMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/workflows/[id]/versions/index.vue")
  },
  {
    name: "admin-workflows___ja",
    path: "workflows",
    meta: indexnzYRUUftblMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/workflows/index.vue")
  },
  {
    name: "admin-workflows-new___ja",
    path: "workflows/new",
    meta: newnb0HmYBVuGMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/admin/workflows/new.vue")
  }
]
  },
  {
    name: "assistants-id-signin___en",
    path: "/en/assistants/:id()/signin",
    meta: signin0qBOgHLFJtMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/assistants/[id]/signin.vue")
  },
  {
    name: "assistants-id-signin___ja",
    path: "/assistants/:id()/signin",
    meta: signin0qBOgHLFJtMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/assistants/[id]/signin.vue")
  },
  {
    name: "auth-callback___en",
    path: "/en/auth/callback",
    meta: callbackESOz4FBvFZMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/auth/callback.vue")
  },
  {
    name: "auth-callback___ja",
    path: "/auth/callback",
    meta: callbackESOz4FBvFZMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/auth/callback.vue")
  },
  {
    name: "auth-callback2___en",
    path: "/en/auth/callback2",
    meta: callback22koegqrpIbMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/auth/callback2.vue")
  },
  {
    name: "auth-callback2___ja",
    path: "/auth/callback2",
    meta: callback22koegqrpIbMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/auth/callback2.vue")
  },
  {
    name: "auth-googleCallback2___en",
    path: "/en/auth/googleCallback2",
    meta: googleCallback27x6yZi5iXLMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/auth/googleCallback2.vue")
  },
  {
    name: "auth-googleCallback2___ja",
    path: "/auth/googleCallback2",
    meta: googleCallback27x6yZi5iXLMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/auth/googleCallback2.vue")
  },
  {
    name: "auth-verify_email___en",
    path: "/en/auth/verify_email",
    meta: verify_emailJylZemT3iMMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/auth/verify_email.vue")
  },
  {
    name: "auth-verify_email___ja",
    path: "/auth/verify_email",
    meta: verify_emailJylZemT3iMMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/auth/verify_email.vue")
  },
  {
    name: dashboards3oIHECcznMeta?.name,
    path: "/en/dashboard",
    meta: dashboards3oIHECcznMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard___en",
    path: "",
    meta: index6QAsXfMQzrMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-mytickets___en",
    path: "mytickets",
    meta: index9nYeGPSohWMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/mytickets/index.vue")
  },
  {
    name: "dashboard-mytickets-new___en",
    path: "mytickets/new",
    meta: newDZmt8c5GdQMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/mytickets/new.vue")
  },
  {
    name: "dashboard-notifications___en",
    path: "notifications",
    meta: indexcTmtn76czvMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/notifications/index.vue")
  },
  {
    name: "dashboard-profile___en",
    path: "profile",
    meta: indexMmaV7NB0zXMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/profile/index.vue")
  },
  {
    name: "dashboard-security___en",
    path: "security",
    meta: indexPWi64yLcCWMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/security/index.vue")
  },
  {
    name: "dashboard-settings-cloudsign___en",
    path: "settings/cloudsign",
    meta: cloudsignhydR3jv7YmMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/settings/cloudsign.vue")
  },
  {
    name: "dashboard-settings-googleChat___en",
    path: "settings/googleChat",
    meta: googleChatBP5O4DA3LoMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/settings/googleChat.vue")
  },
  {
    name: "dashboard-settings-notifications___en",
    path: "settings/notifications",
    meta: notificationslPS2aiy0aMMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/settings/notifications.vue")
  },
  {
    name: "dashboard-settings-tokens___en",
    path: "settings/tokens",
    meta: tokensjBxuxlgk4kMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/settings/tokens.vue")
  },
  {
    name: "dashboard-tasks___en",
    path: "tasks",
    meta: indexRlpBM5oWVNMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/tasks/index.vue")
  },
  {
    name: "dashboard-tickets-id-edit___en",
    path: "tickets/:id()/edit",
    meta: editIWzQ20BBHRMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/tickets/[id]/edit.vue")
  },
  {
    name: "dashboard-tickets-id-editByApprover___en",
    path: "tickets/:id()/editByApprover",
    meta: editByApprovercaHoqaINuVMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/tickets/[id]/editByApprover.vue")
  },
  {
    name: "dashboard-tickets-id___en",
    path: "tickets/:id()",
    meta: indexF14yBGGtj4Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/tickets/[id]/index.vue")
  },
  {
    name: "dashboard-tickets-id-print___en",
    path: "tickets/:id()/print",
    meta: printBUE8G6pdTGMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/tickets/[id]/print.vue")
  },
  {
    name: "dashboard-tickets___en",
    path: "tickets",
    meta: indexlTQypAy9dQMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/tickets/index.vue")
  },
  {
    name: "dashboard-view___en",
    path: "view",
    meta: indexd8fmphOnXNMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/view/index.vue")
  },
  {
    name: "dashboard-view-settings-id-edit___en",
    path: "view/settings/:id()/edit",
    meta: editFFghNnJPFHMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/view/settings/[id]/edit.vue")
  },
  {
    name: "dashboard-view-settings___en",
    path: "view/settings",
    meta: indexkhkbX28QMlMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/view/settings/index.vue")
  },
  {
    name: "dashboard-view-settings-new___en",
    path: "view/settings/new",
    meta: newSHe5qWGRJOMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/view/settings/new.vue")
  },
  {
    name: "dashboard-workflows-search___en",
    path: "workflows/search",
    meta: searchGn3Iu6JsjKMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/workflows/search.vue")
  }
]
  },
  {
    name: dashboards3oIHECcznMeta?.name,
    path: "/dashboard",
    meta: dashboards3oIHECcznMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard___ja",
    path: "",
    meta: index6QAsXfMQzrMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-mytickets___ja",
    path: "mytickets",
    meta: index9nYeGPSohWMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/mytickets/index.vue")
  },
  {
    name: "dashboard-mytickets-new___ja",
    path: "mytickets/new",
    meta: newDZmt8c5GdQMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/mytickets/new.vue")
  },
  {
    name: "dashboard-notifications___ja",
    path: "notifications",
    meta: indexcTmtn76czvMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/notifications/index.vue")
  },
  {
    name: "dashboard-profile___ja",
    path: "profile",
    meta: indexMmaV7NB0zXMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/profile/index.vue")
  },
  {
    name: "dashboard-security___ja",
    path: "security",
    meta: indexPWi64yLcCWMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/security/index.vue")
  },
  {
    name: "dashboard-settings-cloudsign___ja",
    path: "settings/cloudsign",
    meta: cloudsignhydR3jv7YmMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/settings/cloudsign.vue")
  },
  {
    name: "dashboard-settings-googleChat___ja",
    path: "settings/googleChat",
    meta: googleChatBP5O4DA3LoMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/settings/googleChat.vue")
  },
  {
    name: "dashboard-settings-notifications___ja",
    path: "settings/notifications",
    meta: notificationslPS2aiy0aMMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/settings/notifications.vue")
  },
  {
    name: "dashboard-settings-tokens___ja",
    path: "settings/tokens",
    meta: tokensjBxuxlgk4kMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/settings/tokens.vue")
  },
  {
    name: "dashboard-tasks___ja",
    path: "tasks",
    meta: indexRlpBM5oWVNMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/tasks/index.vue")
  },
  {
    name: "dashboard-tickets-id-edit___ja",
    path: "tickets/:id()/edit",
    meta: editIWzQ20BBHRMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/tickets/[id]/edit.vue")
  },
  {
    name: "dashboard-tickets-id-editByApprover___ja",
    path: "tickets/:id()/editByApprover",
    meta: editByApprovercaHoqaINuVMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/tickets/[id]/editByApprover.vue")
  },
  {
    name: "dashboard-tickets-id___ja",
    path: "tickets/:id()",
    meta: indexF14yBGGtj4Meta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/tickets/[id]/index.vue")
  },
  {
    name: "dashboard-tickets-id-print___ja",
    path: "tickets/:id()/print",
    meta: printBUE8G6pdTGMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/tickets/[id]/print.vue")
  },
  {
    name: "dashboard-tickets___ja",
    path: "tickets",
    meta: indexlTQypAy9dQMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/tickets/index.vue")
  },
  {
    name: "dashboard-view___ja",
    path: "view",
    meta: indexd8fmphOnXNMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/view/index.vue")
  },
  {
    name: "dashboard-view-settings-id-edit___ja",
    path: "view/settings/:id()/edit",
    meta: editFFghNnJPFHMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/view/settings/[id]/edit.vue")
  },
  {
    name: "dashboard-view-settings___ja",
    path: "view/settings",
    meta: indexkhkbX28QMlMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/view/settings/index.vue")
  },
  {
    name: "dashboard-view-settings-new___ja",
    path: "view/settings/new",
    meta: newSHe5qWGRJOMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/view/settings/new.vue")
  },
  {
    name: "dashboard-workflows-search___ja",
    path: "workflows/search",
    meta: searchGn3Iu6JsjKMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/dashboard/workflows/search.vue")
  }
]
  },
  {
    name: "expired___en",
    path: "/en/expired",
    meta: expiredPMBS3cvtvyMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/expired.vue")
  },
  {
    name: "expired___ja",
    path: "/expired",
    meta: expiredPMBS3cvtvyMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/expired.vue")
  },
  {
    name: "files-id___en",
    path: "/en/files/:id()",
    meta: indexJwMW7YrwrYMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/files/[id]/index.vue")
  },
  {
    name: "files-id___ja",
    path: "/files/:id()",
    meta: indexJwMW7YrwrYMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/files/[id]/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexOSmglaBbVNMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/index.vue")
  },
  {
    name: "index___ja",
    path: "/",
    meta: indexOSmglaBbVNMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/index.vue")
  },
  {
    name: "invitations-id___en",
    path: "/en/invitations/:id()",
    meta: _91id_938bMCeas1YeMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/invitations/[id].vue")
  },
  {
    name: "invitations-id___ja",
    path: "/invitations/:id()",
    meta: _91id_938bMCeas1YeMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/invitations/[id].vue")
  },
  {
    name: "misoca___en",
    path: "/en/misoca",
    meta: misoca0JBZbVhZDWMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/misoca.vue")
  },
  {
    name: "misoca___ja",
    path: "/misoca",
    meta: misoca0JBZbVhZDWMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/misoca.vue")
  },
  {
    name: "redirect___en",
    path: "/en/redirect",
    meta: redirectgVKnqaIg6DMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/redirect.vue")
  },
  {
    name: "redirect___ja",
    path: "/redirect",
    meta: redirectgVKnqaIg6DMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/redirect.vue")
  },
  {
    name: "select___en",
    path: "/en/select",
    meta: selectWMYo1Zfc5kMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/select.vue")
  },
  {
    name: "select___ja",
    path: "/select",
    meta: selectWMYo1Zfc5kMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/select.vue")
  },
  {
    name: "signin___en",
    path: "/en/signin",
    meta: signinQbBQywGHCmMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/signin.vue")
  },
  {
    name: "signin___ja",
    path: "/signin",
    meta: signinQbBQywGHCmMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/signin.vue")
  },
  {
    name: "startSso___en",
    path: "/en/startSso",
    meta: startSsoMx3lqgaJJKMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/startSso.vue")
  },
  {
    name: "startSso___ja",
    path: "/startSso",
    meta: startSsoMx3lqgaJJKMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/startSso.vue")
  },
  {
    name: "welcome___en",
    path: "/en/welcome",
    meta: welcomeAYOeiRsPBlMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/welcome.vue")
  },
  {
    name: "welcome___ja",
    path: "/welcome",
    meta: welcomeAYOeiRsPBlMeta || {},
    component: () => import("/tmp/build_6214dcf5/frontend_nuxt3/pages/welcome.vue")
  }
]